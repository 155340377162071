<template>
  <div class="home-container">
    <div>
      <img style="width: 100%;display: block;" src="../../assets/img/lingqutop.png" />

    </div>
    <div>
      <!-- <img style="width: 100%;display: block;"src="../../assets/img/lingqujihuo.png"  /> -->

    </div>
    <div style="    font-size: 0.4rem;
    width: 85%;
    margin: 0 auto;
    background: #fff;
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    border-radius: 0.2rem;margin-top: 1rem;">
      <p>黄金会员专属1GB通用流量说明</p>
      <p>1.黄金会员客户专享，每自然月可领取一次。</p>
      <p>2.领取之后立即生效，当月内有效，到期或使用完自动失效，所含流量为国内流量(不含港澳台)。</p>
      <p>3.领取成功后将收到短信通知，请登陆“移动APP”-“流量专区”，查看流量到账情况。</p>
      <p>4.该流量不共享、不转赠、不结转。</p>
      <p>5.MO套餐、无级变速套餐、数据卡、物联卡、随心卡、副卡、行业应用卡、信息机等特殊号码不可订购。</p>
      <p>6.黄金会员权益动态更新，请以中国移动权益超市站内最新公布内容为准。</p>

    </div>
  </div>
</template>

<script>
// import {
//   getUserChannels
// } from '@/api/user'

import {
  mapState
} from 'vuex'
import {
  getItem
} from '@/utils/storage'

export default {
  name: 'home',

  props: {},
  data() {
    return {
      active: 0, //被激活的列表
      channels: [], //频道列表
      isChannelEditShow: false,//频道编辑层
      navlist: [
        {
          name: '未使用'
        },
        {
          name: '已使用'
        },
        {
          name: '已失效'
        }

      ],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    }
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {},
  created() {
    // this.loadChannels()
  },
  mounted() { },
  methods: {
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    async loadChannels() {
      let channels = []
      if (this.user) {
        // 已经登录了，就获取线上用户频道列表
        const {
          data
        } = await getUserChannels()
        channels = data.data.channels
      } else {
        // 没有登录就访问本地频道列表
        const locakChannels = getItem('user-channels')
        // 判断本地频道列表
        if (locakChannels) {
          // 如果本地有频道列表则使用
          channels = locakChannels
        } else {
          // 用户没有登录，也没有本地储存，就请求获取默认推荐列表
          const {
            data
          } = await getUserChannels()
          channels = data.data.channels
        }
      }
      // 把处理好的数据放到 data 中以供模板使用
      this.channels = channels
    }

    // onUpdateActive(index) {
    //   this.active = index
    // }
  }
}
</script>

<style  lang="less">
.home-container {
  p {
    margin: 0.2rem 0;
  }
  .navtab {
    .van-tab {
      font-size: 0.38rem;
    }
    .van-tab--active {
      font-weight: 700;
    }
  }
  .qy-content {
    position: relative;
    width: 100%;
    height: 2.8rem;

    background-size: 100% 100%;
    margin: 0.2rem 0;
    display: flex;
  }
  .qy-fen {
    background-image: url(../../assets/img/quanyifen.png);
  }
  .qy-hui {
    background-image: url(../../assets/img/qianyihui.png);
  }
  .qy-content-img {
    img {
      width: 1.8rem;
      height: 2.2rem;
      display: block;
      padding: 0.3rem 0.35rem;
    }
  }
  .qy-content-info {
    padding-top: 0.2rem;
    span {
      display: block;

      line-height: 1.2rem;
      margin-left: 0.5rem;

      &:nth-child(1) {
        font-size: 0.34rem;
        font-weight: 700;
      }
      &:nth-child(2) {
        font-size: 0.32rem;
        color: #cb4643;
      }
    }
  }
  .qy-button {
    width: 1rem;
    height: 100%;
    background-size: 100% 100%;
    position: absolute;
    right: 0.05rem;
    span {
      font-size: 0.32rem;
      display: block;
      width: 25%;
      color: #ffffff;
      text-align: center;
      margin: 0.75rem auto;
    }
  }
  .qy-button-fen {
    background-image: url(../../assets/img/quanyijihuo.png);
  }

  .qy-button-hui {
    background-image: url(../../assets/img/quanyiweijihuo.png);
    span {
      color: #999999 !important;
    }
  }
}
</style>
